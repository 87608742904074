import React, { ReactElement } from "react";

import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import LongRangeMoversWrapper from "@components/layouts/longrangemoversWrapper";

import MainForm from "@components/longrangemovers/mainBanner";

const Benefits = dynamic(() => import("@components/longrangemovers/benefits"));
const Info = dynamic(() => import("@components/longrangemovers/info"));
const OffersList = dynamic(() => import("@components/shared/offersList"));

export default function LongRangeMoversHome(): ReactElement {
    const { domain } = useDomainContext();
    return (
        <LongRangeMoversWrapper>
            <MainForm />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Info />
            <Benefits />
        </LongRangeMoversWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
